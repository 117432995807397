import React from "react";
import { Icon } from "fds-web";
import getSVGIcon from "./getSVGIcon";

const FDSIcon = ({ name, color, ...rest }) => {
    const svgIcon = getSVGIcon(name);
    if (svgIcon) {
        return svgIcon(rest || {});
    } else return <Icon name={name} color={color || "white"} {...rest} />;
};

export default FDSIcon;
