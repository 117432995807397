import { docLink } from "@common/utils/common-utils";
import { constants } from "js-common/core/common";

export const BG_SESSION_COOKIE_NAME = "bg.session";

export const ALL_SUPPORTED_FORMATS = [...constants.ALL_SUPPORTED_FORMATS];

export const TRANSFORMATION_SUPPORTED_FORMATS = [
    ...constants.TRANSFORMATION_SUPPORTED_FORMATS,
    ...constants.TRANSFORMATION_SUPPORTED_FORMATS.map((item) => item.toUpperCase()),
];

export const IMAGE_FORMATS_FILE_ACCEPT = constants.TRANSFORMATION_SUPPORTED_FORMATS.map(
    (format) => `.${format}`,
).join(", ");

export const THUMBNAIL_FILE_FORMAT_MAX_SIZE = 6;

export const ANONYMOUS_ID_KEY = "pixb_anonymous_id";
export const ANONYMOUS_ID_PARAM_KEY = "anonymous_id";
export const SEGMENT_ANONYMOUS_ID_KEY = "ajs_anonymous_id";

export const mergeTransformation = "merge";
export const transformationOperation = "transformation";

export const CUSTOM_TYPE = "custom";
export const SKIP_CAPTCHA_PREFIX = "skipCode:";
export const ENTERPRISE_CALENDLY_LINK =
    "https://calendly.com/aafiya_patel/pixelbin-io-platform-demo?utm_source=EQ_Pixelbin_payment&utm_medium=Pixelbin&utm_campaign=Book+A+Demo";

export const ENTERPRISE_CALENDLY_ERASE_LINK =
    "https://calendly.com/aafiya_patel/erase-bg-product-overview";

export const ENTERPRISE_CALENDLY_UPSCALE_LINK =
    "https://calendly.com/aafiya_patel/upscale-media-product-overview";

export const ENTERPRISE_CALENDLY_WATERMARK_LINK =
    "https://calendly.com/aafiya_patel/watermark-remover-product-overview";

export const PIXELBIN_STORAGE_TYPE = "pixelbin-storage";
export const ALLOWED_DATASOURCES = [
    "pixelbin-storage",
    "google-cloud-storage",
    "s3",
    "firebase-storage",
    "dropbox",
    "azure-blob-storage",
];
export const ORG_ROUTE_REGEX = /^\/organization\/([^/]+)\/.*/;
export const HIDE_NAVBAR_ROUTE_REGEX =
    /^\/organization\/(-?\d+)\/(playground|new-playground(?!\/glamar(?:\/|$)).*|studio\/.+)$/;
// /^\/organization\/(\d+)\/(playground|new-playground(?:\/.*)?|studio(\/(?!headshots\/[^/]+$).+))$/;

export const docs = {
    url_structure: docLink("/url-structure/"),
    presets: docLink("/presets/"),
};

export const BULK_PLAYGROUND_UPLOAD_CONCURRENCY_LIMIT = 5;
export const BULK_PLAYGROUND_TRANSFORMATION_CONCURRENCY_LIMIT = 1;
export const BULK_PLAYGROUND_UPLOAD_SOURCE = "bulk-playground";
export const BULK_PLAYGROUND_PAGE_SIZE = 10;
export const BULK_PLAYGROUND_BATCH_SIZE_LIMIT = 50;

const AFFILIATE_STORAGE_KEY = "affiliateQueryParams";
const AFFILIATE_QUERY_PARAMS = ["fp_ref"];
const AFFILIATE_QUERY_STORAGE_TYPE = "local"; // local or session | local for localStorage and session for session storage

export const AFFILIATE_QUERY_OPTIONS = {
    storageKey: AFFILIATE_STORAGE_KEY,
    storageType: AFFILIATE_QUERY_STORAGE_TYPE,
    include: AFFILIATE_QUERY_PARAMS,
};

export const OPENAI_OAUTH_QUERY_OPTIONS = {
    storageKey: "openaiOauthQueryParams",
    storageType: "local",
    include: ["state", "redirect_uri", "client_id", "scope"],
};

export const INVOICE_STATUSES = {
    OPEN: "open",
    DRAFT: "draft",
    PAID: "paid",
    VOID: "void",
    UNCOLLECTIBLE: "uncollectible",
    PROCESS_FAILED: "process_failed", // when invoice is paid but got error in process
};

export const propertySearchTag = "pixelbin_console";

export const LAST_CONSENT_APPROVAL_KEY_PREFIX = "consentStatus_";

export const cacheWarmupSupportedDatasources = ["google-cloud-storage"];

export const CURR_WITHOUT_LOWER_DENOM = ["JPY", "KRW"];

export const FILE_VIEWER_SUPPORTED_FORMATS = [
    "txt",
    "csv",
    "xls",
    "xlsx",
    "doc",
    "docx",
    "ppt",
    "pptx",
    "odt",
    "pdf",
];

export const FILE_VIEWER_SUPPORTED_MIME_TYPES = [
    "text/plain",
    "text/csv",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/vnd.oasis.opendocument.text",
];

export const PLAYGROUND_SUPPORTED_VIDEO_MIME_TYPES = [
    "video/mp4",
    "video/x-msvideo",
    "video/quicktime",
    "video/webm",
    "video/mpeg",
];

export const PLAYGROUND_FORMATS = [
    "webp",
    "jpeg",
    "png",
    "jpg",
    "avif",
    "tiff",
    "tif",
    "bmp",
    "heic",
    "heif",
    "txt",
    "mp4",
    "avi",
    "mov",
    "mkv",
    "webm",
    "mpg",
    "mpeg",
];

export const PLAYGROUND_SUPPORTED_FORMATS = [
    ...PLAYGROUND_FORMATS,
    ...PLAYGROUND_FORMATS.map((format) => format.toUpperCase()),
];

export const PLAYGROUND_SUPPORTED_FORMATS_REGEX = new RegExp(
    "^(" + PLAYGROUND_SUPPORTED_FORMATS.join("|") + ")$",
);
