import React, { useEffect, useState, useRef } from "react";
import { initializePaddle } from "@paddle/paddle-js";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { Breakpoints } from "../../../../GlobalStyle";

import BackButton from "common/components/BackButton";
import PaymentsService from "services/payments.service";
import { billingConstants } from "../constants";

import BoxLoader from "common/components/BoxLoader";
import PaddleOrderDetails from "./PaddleOrderDetails";
import environment from "@src/environment";
import { useQuery } from "@common/utils/common-utils";
import { getQueryParams } from "@common/hooks/useTrackingProperties";
import { EventEmitter } from "@common/events/eventEmitter";
import { formatPrice, paddlePricePreview } from "../utils";
import {
    CHECKOUT_CLOSED,
    CHECKOUT_ERROR,
    CHECKOUT_INITIATED,
    PAYMENT_FAILURE,
    PAYMENT_INITIATED,
    PAYMENT_METHOD_SELECTED,
    BUY_BUTTON_CLICK,
} from "@common/events/events";

import useMediaQuery from "@common/components/useMediaQuery";
import ResponsiveLogo from "@common/components/Logo/ResponsiveLogo";
import Button from "@common/components/Button";
import { useSelector } from "react-redux";
import { isEmpty, omitBy } from "lodash";
import { CURR_WITHOUT_LOWER_DENOM } from "@common/constants/constants";
import { ITEM_TYPE } from "@src/__test__/pages/Billing/fixtures";

const Heading = styled.div`
    display: flex;
    font-size: var(--h3-d);
    font-weight: var(--font-weight-600);
    margin: 20px 0;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 13px;

    @media (max-width: ${Breakpoints.tablet}px) {
        font-size: var(--subtitle-2-d);
    }
`;

const OrderSummaryWrapper = styled.div`
    height: 100%;
    color: var(--text-default);
    /* max-width: 400px; */
`;

const PageWrapper = styled.div`
    display: flex;
    position: relative;
    height: 100%;

    @media (max-width: ${Breakpoints.tablet}px) {
        flex-direction: column;
    }
`;

const LeftPanel = styled.div`
    width: ${({ isMobile }) => (isMobile ? "100%" : "65%")};
    overflow: auto;
`;

const PaymentDetailsWrapper = styled.div`
    background-color: var(--surface-0);
    width: 35%;

    @media (max-width: ${Breakpoints.tablet}px) {
        width: 100%;
        background-color: transparent;

        :after {
            content: "";
            height: 5.25rem;
            display: block;
        }
    }
`;

const FormWrapper = styled.div`
    width: ${({ isMobile }) => (isMobile ? "85%" : "65%")};
    margin: ${({ isMobile }) => (isMobile ? "2rem auto" : "5rem auto 0")};

    @media (max-width: ${Breakpoints.mobile}px) {
        padding: 0px;
    }
`;

const PaymentMobileWrapper = styled.div`
    @media (max-width: ${Breakpoints.tablet}px) {
        position: absolute;
        width: 100%;
        bottom: 0px;
        background-color: var(--surface-0);
    }
`;

const PaymentBreakDownWrapper = styled.div`
    width: ${({ isMobile }) => (isMobile ? "85%" : "65%")};
    margin: ${({ isMobile }) => (isMobile ? "0 auto" : "5rem auto 0")};
`;

const DetailsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px 25px 0px 25px;
    width: 100%;
    margin: 10px 0;
    color: var(--text-default);

    @media (max-width: ${Breakpoints.mobile}px) {
        padding: 0px;
    }
`;

const HeadingWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const PaymentWrapper = styled.div`
    padding: 0px 25px 0px 25px;

    @media (max-width: ${Breakpoints.mobile}px) {
        padding: 0px;
    }
`;

const ItemName = styled.div`
    padding: 20px 0px;
    font-weight: var(--font-weight-600);
    font-size: var(--subtitle-1-d);

    @media (max-width: ${Breakpoints.tablet}px) {
        font-size: var(--subtitle-2-d);
    }
`;

const ConfirmationWrapper = styled.div`
    padding: 10px 25px 0px 25px;
    display: flex;
    flex-direction: column;
    gap: 30px;

    @media (max-width: ${Breakpoints.mobile}px) {
        padding: 0px;
    }
`;

const ConfirmationActionBar = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const ConfirmationDesc = styled.div`
    font-size: var(--subtitle-1-d);
    font-weight: var(--font-weight-400);

    @media (max-width: ${Breakpoints.mobile}px) {
        font-size: var(--subtitle-2-d);
    }
`;

const getOrderHeader = (item, itemType, pricing) => {
    if (!item) return "";

    if (itemType === ITEM_TYPE.ADDON) return `Credits Addon`;

    const prefix =
        item.interval === billingConstants.INTERVALS.YEARLY ? " billed yearly" : " billed monthly";

    return `${formatPrice(pricing.subtotal, pricing.currencyCode)}` + prefix;
};

const PaddleCheckoutPage = ({
    orgId,
    selectedItem,
    itemType,
    transactionId,
    mode = "payment",
    confirmationMsg,
}) => {
    const isMobile = useMediaQuery(`(max-width: ${Breakpoints.tablet}px)`);
    const [orderView, toggleOrderView] = useState(false);
    const history = useHistory();
    const user = useSelector((state) => state.authenticationDetails.user);

    const query = useQuery();
    const redirectURL = query.get("redirectUrl");
    const utmSource = query.get("utm_source");
    const isolateFlow = query.get("isolateFlow");

    const [paddle, setPaddle] = useState();
    const [loadingCheckout, setLoadingCheckout] = useState(false);
    const [loadingConfirmation, setLoadingConfirmation] = useState(false);
    const [pricing, setPricing] = useState(null);
    const propsRef = useRef({ orgId, selectedItem, transactionId, redirectURL });

    useEffect(() => {
        propsRef.current = { orgId, selectedItem, transactionId, redirectURL };
    }, [orgId, selectedItem, transactionId, redirectURL]);

    useEffect(() => {
        if (mode === "payment") {
            !paddle && initPaddle();
        } else {
            const fetchLocalPrices = async () => {
                try {
                    const result = await paddlePricePreview([selectedItem], orgId);
                    const price = result.data.details.lineItems[0].totals;
                    const currenyCode = result.data.currencyCode;

                    if (!CURR_WITHOUT_LOWER_DENOM.includes(currenyCode.toUpperCase())) {
                        Object.keys(price).forEach(
                            (key) => (price[key] = parseFloat(price[key]) / 100),
                        );
                    }

                    setPricing({
                        ...price,
                        currencyCode: currenyCode,
                    });
                } catch (err) {
                    setPricing({
                        currencyCode: "USD",
                        subtotal: parseFloat(selectedItem?.amount).toFixed(2),
                        total: parseFloat(selectedItem?.amount).toFixed(2),
                    });
                }
            };
            fetchLocalPrices();
        }
    }, [mode]);

    useEffect(() => {
        if (transactionId && paddle) initiatePayment();

        return () => {
            if (mode === "payment" && transactionId && paddle) paddle.Checkout.close();
        };
    }, [transactionId, paddle]);

    const initPaddle = async () => {
        try {
            const initObj = {
                token: environment.PADDLE_CLIENT_SIDE_TOKEN,
                pwCustomer: {
                    email: user.email,
                },
                eventCallback: function (data) {
                    if (data.name == "checkout.loaded") {
                        setPricing({
                            ...data.data.totals,
                            currencyCode: data.data.currency_code,
                        });
                        setLoadingCheckout(false);
                        EventEmitter.trackingProperties = {
                            ...EventEmitter.trackingProperties,
                            campaign: getQueryParams(),
                        };
                        EventEmitter.dispatch(CHECKOUT_INITIATED, {
                            itemType: itemType,
                            transactionId: data.data.transaction_id,
                        });
                    }
                    if (data.name == "checkout.customer.updated") {
                        setPricing({
                            ...data.data.totals,
                            currencyCode: data.data.currency_code,
                        });
                        linkAddress(data.data.customer.address);
                    }
                    if (data.name === "checkout.discount.applied") {
                        setPricing({
                            ...data.data.totals,
                            currencyCode: data.data.currency_code,
                        });
                    }
                    if (data.name === "checkout.discount.removed") {
                        setPricing({
                            ...data.data.totals,
                            currencyCode: data.data.currency_code,
                        });
                    }
                    if (data.name === "checkout.closed") {
                        EventEmitter.dispatch(CHECKOUT_CLOSED, {
                            itemType: itemType,
                            transactionId: data.data.transaction_id,
                        });
                    }
                    if (data.name === "checkout.error") {
                        EventEmitter.dispatch(CHECKOUT_ERROR, {
                            error: data.error?.code,
                            itemType: itemType,
                            transactionId: data.data.transaction_id,
                            detail: data.error?.detail,
                        });
                    }
                    if (data.name === "checkout.payment.selected") {
                        EventEmitter.dispatch(PAYMENT_METHOD_SELECTED, {
                            type: data.data.payment?.method_details?.type,
                            transactionId: data.data.transaction_id,
                        });
                    }
                    if (data.name === "checkout.payment.initiated") {
                        EventEmitter.dispatch(PAYMENT_INITIATED, {
                            transactionId: data.data.transaction_id,
                        });
                    }
                    if (data.name === "checkout.payment.failed") {
                        EventEmitter.dispatch(PAYMENT_FAILURE, {
                            transactionId: data.data.transaction_id,
                            message: "Payment attempt failed.",
                        });
                    }
                    if (data.name === "checkout.completed") {
                        const {
                            orgId: currentOrgId,
                            selectedItem: selectedItemData,
                            transactionId: currentTransactionId,
                            redirectURL: targetRedirectURL,
                        } = propsRef.current;

                        const finishCheckoutUrl = new URL(
                            `${environment.HINATA_MAIN_DOMAIN}/organization/${currentOrgId}/settings/billing/checkout/finish`,
                        );

                        if (currentTransactionId)
                            finishCheckoutUrl.searchParams.append(
                                "stripeInvoiceId",
                                currentTransactionId,
                            );
                        if (selectedItemData?.name)
                            finishCheckoutUrl.searchParams.append(
                                "planName",
                                selectedItemData.name,
                            );
                        if (selectedItemData?.amount)
                            finishCheckoutUrl.searchParams.append(
                                "planAmount",
                                typeof selectedItemData.amount === "string"
                                    ? parseFloat(selectedItemData.amount).toFixed(2)
                                    : selectedItemData.amount.toFixed(2),
                            );
                        if (data?.data?.customer?.address?.country_code)
                            finishCheckoutUrl.searchParams.append(
                                "countryCode",
                                data.data?.customer?.address?.country_code,
                            );

                        const utm_source_platform =
                            (getQueryParams() || {})["utm_source_platform"] || null;
                        if (utm_source_platform)
                            finishCheckoutUrl.searchParams.append(
                                "utm_source_platform",
                                utm_source_platform,
                            );

                        if (utmSource)
                            finishCheckoutUrl.searchParams.append("utm_source", utmSource);

                        if (targetRedirectURL)
                            finishCheckoutUrl.searchParams.append(
                                "redirectUrl",
                                encodeURIComponent(targetRedirectURL),
                            );

                        window.open(finishCheckoutUrl, "_self");
                    }
                },
            };

            if (environment.ENV !== "production") {
                initObj.environment = "sandbox";
            }

            const paddleInstance = await initializePaddle(initObj);
            setPaddle(paddleInstance);
        } catch (err) {
            console.log(err);
        }
    };

    const linkAddress = (data) => {
        const payload = omitBy(
            {
                addressLine1: data.first_line,
                addressCity: data.city,
                addressState: data.region,
                addressCountry: data.country_code,
                addressPostalCode: data.postal_code,
                pgId: data.id,
            },
            isEmpty,
        );
        PaymentsService.saveBillingAddress(orgId, payload).catch((err) => {
            console.log(err);
        });
    };

    const initiatePayment = async () => {
        if (!paddle) {
            return;
        }

        setLoadingCheckout(true);

        try {
            const credits = selectedItem?.features?.credits?.quantity;
            const isYearly = selectedItem?.interval === billingConstants.INTERVALS.YEARLY;
            EventEmitter.dispatch(BUY_BUTTON_CLICK, {
                amount: selectedItem?.amount * (isYearly ? 12 : 1),
                credits,
            });

            const paddlePayload = {
                transactionId,
                settings: {
                    allowLogout: false,
                    theme: "light",
                    displayMode: selectedItem ? "inline" : "overlay",
                    frameTarget: "checkout-container",
                    frameInitialHeight: "450",
                    frameStyle: "width: 100%; background-color: transparent; border: none; ",
                },
            };

            paddle.Checkout.open(paddlePayload);
        } catch (err) {
            console.log(err);
        }
    };

    const changeSubscription = () => {
        const metadata = { ...(utmSource && { utmSource }) };
        setLoadingConfirmation(true);
        return PaymentsService.buySubscription(orgId, {
            planId: selectedItem._id,
            recurring: true,
            metadata: metadata,
        })
            .then((res) => {
                const { message } = res.data;
                toast.success(message || `Your subscription was changed successfully`, {
                    autoClose: 2000,
                });
            })
            .catch((err) => {
                console.log(err);
                toast.error(
                    err?.response?.data?.message ||
                        "We are unable to change your subscription currently",
                );
            })
            .finally(() => {
                setLoadingConfirmation(false);
                history.push(`/organization/${orgId}/settings/billing`);
            });
    };

    const handleBackButtonState = () =>
        history.push(`/organization/${orgId}/settings/billing/pricing`);

    const renderForm = (formMode) => {
        switch (formMode) {
            case "confirmation":
                return (
                    <BoxLoader
                        stretch={true}
                        opacity={"10"}
                        zIndex={99}
                        loading={loadingConfirmation}
                    >
                        <ConfirmationWrapper>
                            <ConfirmationDesc>{confirmationMsg}</ConfirmationDesc>
                            <div>Do you wish to proceed with this change?</div>
                            <ConfirmationActionBar>
                                <Button width="100%" onClick={changeSubscription}>
                                    Update Subscription
                                </Button>
                                <Button
                                    mode="secondary"
                                    width="100%"
                                    onClick={() =>
                                        history.push(
                                            `/organization/${orgId}/settings/billing/pricing`,
                                        )
                                    }
                                >
                                    Cancel
                                </Button>
                            </ConfirmationActionBar>
                        </ConfirmationWrapper>
                    </BoxLoader>
                );
            case "payment":
                return (
                    <BoxLoader stretch={true} opacity={"10"} zIndex={99} loading={loadingCheckout}>
                        <PaymentWrapper
                            className="checkout-container"
                            data-testid="checkout-container"
                        ></PaymentWrapper>
                    </BoxLoader>
                );

            default:
                break;
        }
    };

    const renderTitle = (titleMode) => {
        let heading = "";
        switch (titleMode) {
            case "confirmation":
                heading = "Confirm subscription update";
                break;
            case "payment":
            default:
                heading = "Complete your purchase";
                break;
        }

        const utm_source_platform = (getQueryParams() || {})["utm_source_platform"] || null;

        return (
            <Heading data-testid={`payment-flow-heading-${titleMode}`}>
                {isolateFlow
                    ? isolateFlow !== "true" && <BackButton onClick={handleBackButtonState} />
                    : utm_source_platform !== "mobile" && (
                          <BackButton onClick={handleBackButtonState} />
                      )}
                {heading}
            </Heading>
        );
    };

    return (
        <>
            <PageWrapper data-testid="checkout-page">
                <LeftPanel isMobile={isMobile}>
                    <FormWrapper isMobile={isMobile}>
                        <DetailsWrapper>
                            {isMobile && (
                                <ResponsiveLogo
                                    height={"auto !important"}
                                    width={"9rem"}
                                    isReponsive={false}
                                />
                            )}
                            {renderTitle(mode)}
                        </DetailsWrapper>
                        {renderForm(mode)}
                    </FormWrapper>
                </LeftPanel>
                <PaymentDetailsWrapper>
                    <PaymentMobileWrapper>
                        <PaymentBreakDownWrapper isMobile={isMobile} data-testid="order-summary">
                            <DetailsWrapper>
                                {!isMobile && (
                                    <ResponsiveLogo
                                        height={"auto"}
                                        width={"9rem"}
                                        isReponsive={false}
                                    />
                                )}
                                <HeadingWrapper>
                                    <ItemName>
                                        {pricing && getOrderHeader(selectedItem, itemType, pricing)}
                                    </ItemName>
                                    {isMobile && (
                                        <BackButton
                                            onClick={() => {
                                                toggleOrderView(!orderView);
                                            }}
                                            mode="unfilled"
                                            style={{
                                                transform: `rotate(${
                                                    orderView ? "270deg" : "90deg"
                                                })`,
                                            }}
                                        />
                                    )}
                                </HeadingWrapper>
                            </DetailsWrapper>
                            {(!isMobile || orderView) && (
                                <DetailsWrapper>
                                    {selectedItem && (
                                        <OrderSummaryWrapper>
                                            <PaddleOrderDetails
                                                selectedItem={selectedItem}
                                                itemType={itemType}
                                                pricing={pricing}
                                            />
                                        </OrderSummaryWrapper>
                                    )}
                                </DetailsWrapper>
                            )}
                        </PaymentBreakDownWrapper>
                    </PaymentMobileWrapper>
                </PaymentDetailsWrapper>
            </PageWrapper>
        </>
    );
};

export default PaddleCheckoutPage;
