export const SIDEBAR_OPTIONS = [
    {
        name: "Background",
        value: "background",
        icon: "ic_background",
        contentWeb: [
            { icon: "ic_background_replace", text: "Remove Background", value: "removeBg" },
            { icon: "ic_generate_background", text: "Generate Background", value: "genBg" },
            { icon: "ic_shadow", text: "Add Shadows", value: "shadow" },
        ],
        contentMob: [
            { icon: "ic_background_replace", text: "Remove", value: "removeBg" },
            { icon: "ic_generate_background", text: "Generate", value: "genBg" },
            { icon: "ic_shadow", text: "Shadows", value: "shadow" },
        ],
        showMobile: true,
        showWeb: true,
    },
    {
        name: "\u00A0\u00A0\u00A0Enhance\u00A0\u00A0\u00A0",
        value: "enhance",
        icon: "ic_image_enhance",
        contentWeb: [
            { icon: "ic_background_replace", text: "Upscale image", value: "upscaleImage" },
        ],
        contentMob: [],
        showMobile: true,
        showWeb: true,
    },
    {
        name: "\u00A0Watermark\u00A0",
        value: "watermark",
        icon: "ic_watermark_remover",
        contentWeb: [
            { icon: "ic_background_replace", text: "WaterMark Remover", value: "watermarkRemover" },
        ],
        contentMob: [],
        showMobile: true,
        showWeb: true,
    },
    {
        name: "Transform",
        value: "transform",
        icon: "ic_photo_size",
        contentWeb: [],
        contentMob: [
            { icon: "ic_background_replace", text: "Resize", value: "resize" },
            { icon: "ic_crop", text: "Crop", value: "crop" },
        ],
        showMobile: true,
        showWeb: false,
    },
    {
        name: "Adjust",
        value: "adjust",
        icon: "ic_adjust",
        contentWeb: [],
        contentMob: [],
        showMobile: true,
        showWeb: false,
    },
    {
        name: "Templates",
        value: "templates",
        icon: "ic_template_2",
        contentWeb: [],
        contentMob: [],
        showMobile: true,
        showWeb: true,
    },
];

export const REMOVEBG_DROPDOWN_OPTIONS = [
    { name: "General", icon: "ic_image_new", value: "general" },
    { name: "E-commerce", icon: "ic_cart", value: "e-commerce" },
    { name: "Car", icon: "ic_car", value: "car" },
    { name: "Human", icon: "ic_profile", value: "human" },
    { name: "Object", icon: "ic_orders", value: "object" },
];

export const SHADOW_OPTIONS = [
    {
        name: "Soft",
        value: "soft",
        shadow: "shadow-[3px_6px_10px_0px_#00000021]",
        intensity: "",
        angle: "",
    },
    {
        name: "Hard",
        value: "hard",
        shadow: "shadow-[3px_3px_0px_0px_#00000026]",
        intensity: "",
        angle: "",
    },
    {
        name: "Custom",
        value: "custom",
        shadow: "shadow-[4px_4px_2px_0px_#0000001A]",
        intensity: "",
        angle: "",
    },
];

export const ADJUST_OPTIONS = [
    {
        name: "Hue",
        value: "hue",
        low: 0,
        high: 100,
        trackGradient:
            "linear-gradient(90deg, #FF4600 0%, #FFE201 17%, #0FFF00 34%, #0DEAFF 51%, #0008FF 68%, #FE01F7 85%, #FF004D 100%)",
    },
    {
        name: "Saturation",
        value: "saturation",
        low: 0,
        high: 100,
        trackGradient: "linear-gradient(to right, #868686, #F90609)",
    },
    {
        name: "Brightness",
        value: "brightness",
        low: 0,
        high: 100,
        trackGradient: "linear-gradient(to right, black, white)",
    },
    {
        name: "Tint",
        value: "tint",
        low: 0,
        high: 100,
        trackGradient: `linear-gradient(90deg, #FF4600 0%, #FFFFFF 100%)`,
    },
];

export const UPSCALE_OPTIONS = [
    {
        name: "2X",
        value: "twox",
        multiplier: 2,
    },
    {
        name: "4X",
        value: "fourx",
        multiplier: 4,
    },
    {
        name: "8X",
        value: "eightx",
        multiplier: 8,
    },
];
export const WATERMARK_OPTIONS = [
    {
        name: "Auto",
        value: "auto",
        icon: "ic_auto_remove",
    },
    {
        name: "Manual",
        value: "manual",
        icon: "ic_manual_remove",
    },
];

export const REMOVE_BG_OPTIONS = [
    {
        name: "Add Background",
        value: "addBackground",
        children: [{ option: "Color" }, { option: "Image" }],
        icon: "ic_watermark_remover",
    },
    {
        name: "Generate background",
        value: "genBg",
        children: [],
        icon: "ic_generate_background",
    },
];

export const HEADSHOT_OPTIONS = {
    name: [
        { name: "Man", value: "man" },
        { name: "Woman", value: "woman" },
        { name: "Boy", value: "boy" },
        { name: "Girl", value: "girl" },
        { name: "Baby", value: "baby" },
        { name: "Dog", value: "dog" },
        { name: "Cat", value: "cat" },
    ],
    ethnicity: [
        { name: "Caucasian", value: "caucasian" },
        { name: "Black", value: "black" },
        { name: "Hispanic", value: "hispanic" },
        { name: "Korean", value: "korean" },
        { name: "Japanese", value: "japanese" },
        { name: "Chinese", value: "chinese" },
        { name: "Philippine", value: "philippine" },
        { name: "Indian", value: "indian" },
    ],
    hairColor: [
        { name: "Blonde", value: "blonde" },
        { name: "Brown", value: "brunette" },
        { name: "Red", value: "red hair" },
        { name: "Black", value: "black hair" },
    ],
    eyeColor: [
        { name: "Blue", value: "blue eyes" },
        { name: "Brown", value: "brown eyes" },
        { name: "Green", value: "green eyes" },
        { name: "Gray", value: "gray eyes" },
        { name: "Black", value: "black eyes" },
    ],
    age: [
        { name: "20-29", value: "20 yo" },
        { name: "30-39", value: "30 yo" },
        { name: "40-49", value: "40 yo" },
        { name: "50-59", value: "50 yo" },
        { name: "60-69", value: "60 yo" },
        { name: "70-79", value: "70 yo" },
    ],
};

export const HEADSHOT_STATES = {
    accepted: "ACCEPTED",
    success: "SUCCESS",
    failure: "FAILURE",
};

export const HEADSHOT_IMAGE_STATES = {
    uploaded: "UPLOADED",
    validating: "VALIDATING",
    accepted: "ACCEPTED",
    warning: "WARNING",
    discarded: "DISCARDED",
};
