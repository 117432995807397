import React, { lazy, Suspense, useEffect } from "react";
import useRedirectUrl from "@common/hooks/useRedirectUrl";
import { hot } from "react-hot-loader";
import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter, Route, Switch, useLocation } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import Loader from "./common/components/Loader";
import PrivateRoute from "./common/components/PrivateRoute";
import ReportIssue from "./common/components/ReportIssue";
import ScrollToTop from "./common/components/ScrollToTop";
import ToastNotification from "./common/components/ToastNotification";
import { BreakpointProvider } from "./common/hooks/Breakpoint";
import useScriptsHooks from "./common/hooks/useScriptsHooks";
import { CropContextProvider } from "./pages/Playground/CropContext";
import routes from "./routes";
import UserService from "./services/user.service";
import useGoogleOneTapImpressions from "@common/hooks/useGoogleOneTapImpressions";
import { GTMContextProvider } from "@common/components/GTM/GTMContext";
import { ResizeContextProvider } from "./pages/Playground/ResizeContext";
import { LOAD_FRESHCHAT } from "@common/events/events";
import { EventEmitter } from "@common/events/eventEmitter";
import { setShowNavbar } from "@common/redux/actions/menuActions";
import { HIDE_NAVBAR_ROUTE_REGEX, ORG_ROUTE_REGEX } from "@common/constants/constants";
import { ThemeProvider as FDSThemeProvider } from "@common/components/FDSComponents";

const Header = lazy(() => import("./common/components/Header"));

const Fallback = styled.div`
    height: 100%;
    background: var(--bg-color);
`;

const Wrapper = styled.div`
    height: 100%;
    overflow: hidden;
`;

const ComponentWrapper = styled.div`
    background-color: var(--bg-color);
    overflow: auto;
    margin-left: ${({ isUserLoggedIn, showTopNavbar }) =>
        isUserLoggedIn && showTopNavbar ? "76px" : "0px"};
    margin-top: ${({ isUserLoggedIn, showTopNavbar, showBanner, bannerHeight }) =>
        isUserLoggedIn && showTopNavbar ? (showBanner ? `${bannerHeight + 56}px` : "56px") : "0px"};
    height: ${({ isUserLoggedIn, showTopNavbar, showBanner, bannerHeight }) =>
        isUserLoggedIn && showTopNavbar
            ? showBanner
                ? `calc(100% - ${bannerHeight + 56}px)`
                : "calc(100% - 56px)"
            : "100%"};
    border-top-left-radius: ${({ isUserLoggedIn, showTopNavbar }) =>
        isUserLoggedIn && showTopNavbar ? "var(--fds-borderradius-core-8)" : "0px"};

    @media (max-width: 767px) {
        margin-left: 0px;
        border-top-left-radius: 0px;
        margin-top: ${({ isUserLoggedIn, showTopNavbar, showBanner, bannerHeight }) =>
            isUserLoggedIn && showTopNavbar
                ? showBanner
                    ? `${bannerHeight + 64}px`
                    : "64px"
                : "0px"};
        height: ${({ isUserLoggedIn, showTopNavbar, showBanner, bannerHeight }) =>
            isUserLoggedIn && showTopNavbar
                ? showBanner
                    ? `calc(100% - ${bannerHeight + 64}px)`
                    : "calc(100% - 64px)"
                : "100%"};
    }
`;

const ModalRoot = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1003;
`;

const SheetRoot = styled.div`
    position: fixed;
    z-index: 999;
`;

const CropRoot = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1004;
`;

const ResizeRoot = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1004;
`;

const JavascriptEditor = styled.div`
    position: fixed;
    top: "0";
    left: "0";
    z-index: 1003;
`;

const themeObject = {
    font: {
        fontFamily: "",
    },
    spacing: {
        "dimensions-28": "",
    },
    breakpoints: {},
    color: [
        {
            name: "theme 1",
            value: {
                light: {
                    "fds-primary-10": "#F7F5FC",
                    "fds-primary-20": "var(--fds-purple-20)",
                    "fds-primary-30": "var(--fds-purple-30)",
                    "fds-primary-40": "var(--fds-purple-40)",
                    "fds-primary-50": "#6933FC",
                    "fds-primary-60": "var(--fds-purple-60)",
                    "fds-primary-70": "var(--fds-purple-70)",
                    "fds-primary-90": "var(--fds-purple-90)",
                    "fds-primary-inverse-0": "var(--fds-purple-inverse-0",
                    "fds-neutral-grey-80": "#000000A6",
                    "fds-text-subdued-1": "#000000A6",
                    "fds-violet-50": "#1eccb0",
                    "fds-purple-50": "#6933fa",
                },
            },
        },
    ],
};

const App = () => {
    useScriptsHooks();
    useRedirectUrl();
    useGoogleOneTapImpressions();
    const scrollTopVisible = useSelector((state) => state.uploadDetails.scrollTopVisible);
    const showTopNavbar = useSelector((state) => state.menuDetails.showTopNavbar);
    const isLoaderOverlayVisible = useSelector((state) => state.menuDetails.isLoaderOverlayVisible);
    const { isUserLoggedIn } = useSelector((state) => state.authenticationDetails);
    const { showBanner } = useSelector((state) => state.menuDetails.showBanner);
    const bannerHeight = useSelector((state) => state.menuDetails.bannerHeight);
    const dispatch = useDispatch();

    useEffect(() => {
        if (isUserLoggedIn) {
            EventEmitter.dispatch(LOAD_FRESHCHAT);
        }
    }, [isUserLoggedIn]);

    useEffect(() => {
        UserService.getCurrentUserSession()
            .then((response) => {
                const message = {
                    type: "sessionData",
                    data: response.data,
                };
                window.parent.postMessage(JSON.stringify(message), "*");
            })
            .catch((error) => {
                const message = {
                    type: "sessionData",
                    data: error.response.data,
                };
                window.parent.postMessage(JSON.stringify(message), "*");
            });
    }, []);

    const NavbarController = () => {
        const location = useLocation();

        useEffect(() => {
            const isHideNavbarPath = HIDE_NAVBAR_ROUTE_REGEX.test(location.pathname);
            const isOrgPath = ORG_ROUTE_REGEX.test(location.pathname);

            if (isHideNavbarPath) {
                setShowNavbar(false, dispatch);
            } else if (isOrgPath) {
                setShowNavbar(true, dispatch);
            }
        }, [location.pathname]);

        return null;
    };

    return (
        <CropContextProvider>
            <ResizeContextProvider>
                <Wrapper data-testid="app">
                    <BrowserRouter>
                        <BreakpointProvider>
                            <GTMContextProvider>
                                <NavbarController />
                                <FDSThemeProvider theme={themeObject} mode="light" />
                                <ThemeProvider theme={{ scrollTopVisible }}>
                                    <ScrollToTop />
                                    <Suspense fallback={<Fallback />}>
                                        <Header />
                                    </Suspense>
                                    <ComponentWrapper
                                        id="componentWrapper"
                                        isUserLoggedIn={isUserLoggedIn}
                                        showTopNavbar={showTopNavbar}
                                        showBanner={showBanner}
                                        bannerHeight={bannerHeight}
                                    >
                                        <Suspense fallback={<Fallback />}>
                                            <Switch>
                                                {routes.map((route, index) => (
                                                    <Route path={route.path} key={index}>
                                                        <PrivateRoute
                                                            component={route.component}
                                                            guard={route.guard}
                                                            path={route.path}
                                                        />
                                                    </Route>
                                                ))}
                                            </Switch>
                                        </Suspense>
                                    </ComponentWrapper>
                                </ThemeProvider>
                            </GTMContextProvider>
                        </BreakpointProvider>
                        <ReportIssue />
                    </BrowserRouter>
                    <ToastNotification />
                    {isLoaderOverlayVisible && <Loader />}
                    <ModalRoot id="modal-root" />
                    <SheetRoot id="sheet-root" />
                    <CropRoot id="crop-root" />
                    <ResizeRoot id="resize-root" />
                    <JavascriptEditor id="javascript-editor" />
                </Wrapper>
            </ResizeContextProvider>
        </CropContextProvider>
    );
};

export default hot(module)(App);
