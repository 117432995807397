import { createSlice } from "@reduxjs/toolkit";
import { TRANSFORMATION_TYPE } from "@common/constants/studio.constants";
import { ADJUST_OPTIONS } from "@src/pages/Studio/ImageStudio/StudioSideBar/constants";

export const MODAL_TYPES = {
    DOWNLOAD: "download",
    SUBSCRIBE: "subscribe",
    BRUSH_SUBSCRIBE: "brushSubscribe",
    UPSCALE_SUBSCRIBE: "upscaleSubscribe",
    AI_APPS_SUBSCRIBE: "aiAppsSubscribe",
    STUDIO_SUBSCRIBE: "studioSubscribe",
    BATCH_EDIT: "batchEdit",
    DOWNLOAD_EXHAUST: "downloadExhaust",
    BRUSH_SUBSCRIBE_WEB: "brushSubscribeWeb",
    AI_APPS_SUBSCRIBE_WEB: "aiAppsSubscribeWeb",
    UPSCALE_SUBSCRIBE_WEB: "upscaleSubscribeWeb",
    DOWNLOAD_MOBILE_OPTIONS: "downloadMobileOptions",
};

const initialState = {
    imageList: [],
    modals: Object.keys(MODAL_TYPES).reduce((acc, modal) => {
        acc[MODAL_TYPES[modal]] = false;
        return acc;
    }, {}),
    changePanel: {
        isChangePanel: false,
        changePanelContent: null,
    },
    transformationType: TRANSFORMATION_TYPE[0],
    bgType: 0,
    watermarkType: 0,
    upscaleType: 0,
    activeMenu: "default",
    activeCropSize: "9/16",
    loadImage: false,
    adjust: ADJUST_OPTIONS.reduce(
        (acc, { value }) => ({
            ...acc,
            [value]: 0,
        }),
        {},
    ),
    buttonStates: {
        zoomOut: false,
        zoomIn: false,
        undo: true,
        redo: true,
        delete: false,
    },
    studioSelectedImage: null,
    currentZoom: 0,
    size: {
        width: 0,
        height: 0,
        rotate: 0,
        aspectRatio: 1,
        rotateClockwise: 0,
        flipX: 1,
        flipY: 1,
        zoomLevel: 1,
        lockClicked: false,
    },
};

// helper functions for reducers
const resetState = (state, fields) => {
    fields.forEach((field) => {
        state[field] = initialState[field];
    });
};

const createSetter = (property) => (state, action) => {
    state[property] = action.payload;
};

const createAdjustSetter = (property) => (state, action) => {
    state[property] = {
        ...state[property],
        ...action.payload,
    };
};

// actual slice
const studioSlice = createSlice({
    name: "studio",
    initialState,
    reducers: {
        setModalState: (state, action) => {
            const { modalType, isOpen } = action.payload;
            state.modals[modalType] = isOpen;
        },

        closeAllModals: (state) => {
            Object.keys(state.modals).forEach((modalKey) => {
                state.modals[modalKey] = false;
            });
        },

        setBgType: createSetter("bgType"),
        setWatermarkType: createSetter("watermarkType"),
        setUpscaleType: createSetter("upscaleType"),
        setActiveMenu: createSetter("activeMenu"),
        setActiveCropSize: createSetter("activeCropSize"),
        setLoadImage: createSetter("loadImage"),
        setAdjust: createAdjustSetter("adjust"),
        setStudioSelectedImage: createSetter("studioSelectedImage"),
        setCurrentZoom: createSetter("currentZoom"),
        setTransformationType: createSetter("transformationType"),

        setButtonStates: (state, action) => {
            state.buttonStates = { ...state.buttonStates, ...action.payload };
        },

        setChangePanel: (state, action) => {
            const { isChangePanel, changePanelContent } = action.payload;
            state.changePanel = { isChangePanel, changePanelContent };
        },

        setImageList: (state, action) => {
            state.imageList = [...action.payload];
        },

        setSize: (state, action) => {
            state.size = { ...state.size, ...action.payload };
        },

        handleSizeTransform: (state, action) => {
            switch (action.payload) {
                case "ic_rotate":
                    state.size.rotateClockwise += 90;
                    state.size.rotate += 90;
                    break;
                case "ic_flip_horizontal":
                    state.size.flipX *= -1;
                    break;
                case "ic_flip_vertical":
                    state.size.flipY *= -1;
                    break;
                default:
                    break;
            }
        },

        setTransformations: (state, action) => {
            const { imageId, newTransformations, newOnlyCropAdjustTransformations, newRedoList } =
                action.payload;

            state.imageList = state.imageList.map((image) =>
                image.idx === imageId
                    ? {
                          ...image,
                          transformations: [...image.transformations, newTransformations],
                          onlyCropAdjustTransformations: Array.isArray(
                              newOnlyCropAdjustTransformations,
                          )
                              ? [
                                    ...image.onlyCropAdjustTransformations,
                                    ...newOnlyCropAdjustTransformations,
                                ]
                              : [
                                    ...image.onlyCropAdjustTransformations,
                                    newOnlyCropAdjustTransformations,
                                ],
                          redoList: [...(newRedoList || [])],
                      }
                    : image,
            );
        },

        resetTransformation: (state) => {
            resetState(state, [
                "bgType",
                "watermarkType",
                "upscaleType",
                "activeMenu",
                "changePanel",
            ]);
        },
    },
});

export const {
    setImageList,
    setModalState,
    setActiveCropSize,
    setChangePanel,
    setBgType,
    setWatermarkType,
    setUpscaleType,
    setActiveMenu,
    setLoadImage,
    setTransformationList,
    setAdjust,
    setButtonStates,
    setStudioSelectedImage,
    setCurrentZoom,
    setTransformationType,
    setTransformations,
    closeAllModals,
    resetTransformation,
    setSize,
    handleSizeTransform,
} = studioSlice.actions;

// Selectors for new states
export const selectImageList = (state) => {
    return state.studio.imageList;
};
export const selectLoadImage = (state) => state.studio.loadImage;
export const selectAdjust = (state) => state.studio.adjust;
export const selectButtonStates = (state) => state.studio.buttonStates;
export const selectBgType = (state) => state.studio.bgType;
export const selectWatermarkType = (state) => state.studio.watermarkType;
export const selectUpscaleType = (state) => state.studio.upscaleType;
export const selectActiveMenu = (state) => state.studio.activeMenu;
export const selectActiveCropSize = (state) => state.studio.activeCropSize;
export const selectModalState = (state, modalType) => state.studio.modals[modalType];
export const selectChangePanel = (state) => state.studio.changePanel;
export const selectTransformationType = (state) => state.studio.transformationType;
export const selectStudioSelectedImage = (state) => state.studio.studioSelectedImage;
export const selectCurrentZoom = (state) => state.studio.currentZoom;
export const selectSize = (state) => state.studio.size;

export default studioSlice.reducer;
