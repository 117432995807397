import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Button } from "fds-web";

const appearanceStyles = {
    default: {
        bg: "bg-[#6933fa] hover:bg-[#370B8F]",
        textPrimary: "text-white",
        text: "text-[#000093]",
        hoverText: "hover:text-[#000093]",
        hoverBg: "hover:bg-[#e8e8fc]",
        border: "border border-[#e0e0e0]",
        underline: "hover:underline",
    },
    positive: {
        bg: "bg-[#25ab21] hover:bg-[#25ab21]",
        textPrimary: "text-white",
        text: "text-[#135610]",
        hoverText: "hover:text-[#135610]",
        hoverBg: "hover:bg-[#e9f7e9]",
        border: "border border-[#e0e0e0]",
        underline: "hover:underline",
    },
    negative: {
        bg: "bg-[#f50031] hover:bg-[#f50031]",
        textPrimary: "text-white",
        text: "text-[#660014]",
        hoverText: "hover:text-[#660014]",
        hoverBg: "hover:bg-[#fee6ea]",
        border: "border border-[#e0e0e0]",
        underline: "hover:underline",
    },
    contrast: {
        bg: "bg-white hover:bg-[#e8e8fc]",
        textPrimary: "text-[#141414]",
        text: "text-gray-800",
        hoverText: "hover:text-[#660014]",
        hoverBg: "hover:bg-gray-800/20",
        border: "border border-[#e0e0e0]",
        underline: "hover:underline",
    },
    ai: {
        bg: "ai-gradient-bg",
        textPrimary: "text-white",
        text: "text-[#000093]",
        hoverText: "hover:text-[#000093]",
        hoverBg: "hover:bg-[#e8e8fc]",
        border: "border border-[#e0e0e0]",
        underline: "hover:underline",
    },
    warning: {
        bg: "bg-[#f06d0f] hover:bg-[#7d2f08]",
        textPrimary: "text-white",
        text: "text-[#7d2f08]",
        hoverText: "hover:text-[#7d2f08]",
        hoverBg: "hover:bg-[#fef0e7]",
        border: "border border-[#e0e0e0]",
        underline: "hover:underline",
    },
};

const FdsButton = ({ kind = "primary", appearance = "default", className, children, ...props }) => {
    const kindStyles = {
        primary: `${appearanceStyles[appearance]?.textPrimary} border-none ${appearanceStyles[appearance]?.bg}`,
        secondary: clsx(
            "bg-transparent border border-solid",
            appearanceStyles[appearance]?.border,
            appearanceStyles[appearance]?.text,
            appearanceStyles[appearance]?.hoverBg,
            appearanceStyles[appearance]?.hoverText,
        ),
        tertiary: clsx(
            "bg-transparent border-none",
            appearanceStyles[appearance]?.text,
            `hover:underline hover:bg-transparent`,
            appearanceStyles[appearance]?.hoverText,
        ),
    };

    return (
        <Button
            {...props}
            kind={kind}
            appearance={appearance}
            className={clsx(kindStyles[kind], className)}
        >
            {children}
        </Button>
    );
};
FdsButton.propTypes = {
    kind: PropTypes.oneOf(["primary", "secondary", "tertiary"]),
    appearance: PropTypes.oneOf(["default", "positive", "negative", "contrast", "ai", "warning"]),
    size: PropTypes.oneOf(["s", "m", "l"]),
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    label: PropTypes.string,
    onBlur: PropTypes.func,
    onClick: PropTypes.func,
    onFocus: PropTypes.func,
    href: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.shape({}), //
    children: PropTypes.node.isRequired,
};

FdsButton.defaultProps = {
    kind: "primary",
    appearance: "default",
    size: "m",
    fullWidth: false,
    disabled: false,
    loading: false,
    label: "",
    onBlur: undefined,
    onClick: undefined,
    onFocus: undefined,
    href: undefined,
    id: undefined,
    name: undefined,
    className: "",
    style: {},
};

export default FdsButton;
