import React from "react";
import Crown from "@common/assets/svgs/studio/Crown.svg";
import ColorPallette from "@common/assets/svgs/studio/ColorPallette.svg";
import DefaultImg from "@common/assets/svgs/studio/DefaultImg.svg";
import AiIcon from "@common/assets/svgs/studio/AiIconStar.svg";
import ColorPalletteSelected from "@common/assets/svgs/studio/ColorPalletteSelected.svg";
import DefaultImageSelected from "@common/assets/svgs/studio/DefaultImageSelected.svg";

import {
    Freeform,
    Original,
    OneByOne,
    FourByFive,
    NineBySixteen,
    TwoByThree,
    TwentyOneByNine,
    FourByThree,
    ThreeByTwo,
    ThreeByFour,
    TwoByOne,
} from "./../../pages/MiniStudio/CropMenuIcon";

import { Text } from "@components/FDSComponents";
import environment from "@src/environment";

export const TRANSFORMATION_TYPE = ["eraseBg", "watermarkRemover", "upscaler"];
export const BACKGROUND_TYPE_LIST = [
    {
        icon: <img src={ColorPallette} />,
        label: "Color",
        value: "color",
        selected: true,
        disabled: false,
        selectedImage: <img src={ColorPalletteSelected} />,
    },
    {
        icon: <img src={DefaultImg} />,
        label: "Image",
        value: "image",
        selected: false,
        disabled: false,
        selectedImage: <img src={DefaultImageSelected} />,
    },
    {
        label: (
            <div className="flex items-center gap-1">
                {/* <Crown className="w-6 h-6" /> */}
                <img src={Crown} />
                <Text>AI</Text>
            </div>
        ),
        icon: (
            <div className="relative">
                <img src={AiIcon} />
                {/* <Crown className="h-4 w-4 absolute -top-1 right-[3.5px]" /> */}
                <img src={Crown} />
            </div>
        ),
        value: "ai",
        selected: false,
        disabled: false,
    },
];

export const TAB_COLOR_LIST = [
    "conic-gradient(from 180deg at 50% 50%, #EB0D0D 0deg, #FE0 60.00000178813934deg, #39FF5B 120.00000357627869deg, #00FFB7 180deg, #79C4DB 240.00000715255737deg, #F203FF 299.9999928474426deg, #EB0D0D 360deg)",
    "#EA1D63",
    "#673AB7",
    "#2295F2",
    "#4CAF50",
    "#FFD13B",
];

export const MTRANSFORMATION_MENU_IMAGE_LIST = ["Custom", "Beaches", "Pastel", "Wood", "Chrome"];

export const WATERMARK_TYPE_LIST = [
    {
        label: "Auto",
        value: "auto",
        selected: true,
        disabled: false,
    },
    {
        label: (
            <div className="flex items-center gap-1">
                {/* <Crown className="w-6 h-6" /> */}
                <img src={Crown} />
                <Text>Brush</Text>
            </div>
        ),
        icon: (
            <div className="relative">
                <img src={AiIcon} />
                {/* <Crown className="h-4 w-4 absolute -top-1 right-[3.5px]" /> */}
                <img src={Crown} />
            </div>
        ),
        value: "brush",
        selected: false,
        disabled: false,
    },
];

export const WATERMARK_TOGGLE_OPTIONS = [
    { label: "Remove Text", id: "removeText", checked: false },
    { label: "Remove Logo", id: "removeLogo", checked: false },
];

export const UPSCALE_TYPE_LIST = [
    {
        label: (
            <div className="flex items-center gap-1 bg-[#F5F5F5] rounded-full border border-surface-20 p-4 md:px-0 md:py-0">
                <Text className="text-[#B5B5B5] md:text-inherit">2x</Text>
            </div>
        ),
        value: "2x",
        selected: true,
        disabled: false,
    },
    {
        label: (
            <div className="flex items-center gap-1 rounded-full border border-surface-20 p-4 md:px-0 md:py-0">
                {/* <Crown className="hidden md:block w-6 h-6" /> */}
                <img src={Crown} />
                <Text>4x</Text>
            </div>
        ),
        icon: (
            <div className="relative">
                {/* <Crown className="h-4 w-4 absolute top-2 -right-5" /> */}
                <img src={Crown} />
            </div>
        ),
        value: "4x",
        selected: false,
        disabled: false,
    },
    {
        label: (
            <div className="flex items-center gap-1 rounded-full border border-surface-20 p-4 md:px-0 md:py-0">
                {/* <Crown className="hidden md:block w-6 h-6" /> */}
                <img src={Crown} />
                <Text>8x</Text>
            </div>
        ),
        icon: (
            <div className="relative">
                {/* <Crown className="h-4 w-4 absolute top-2 -right-5" /> */}
                <img src={Crown} />
            </div>
        ),
        value: "8x",
        selected: false,
        disabled: false,
    },
];

export const CROP_MENUS = [
    { id: "freeform", label: "Freeform", value: "freeform", icon: Freeform },
    { id: "original", label: "Original", value: "original", icon: Original },
    { id: "4/5", label: "4:5", value: "4/5", icon: FourByFive },
    { id: "16/9", label: "16:9", value: "16/9", icon: OneByOne },
    { id: "1/1", label: "1:1", value: "1/1", icon: OneByOne },
    { id: "9/16", label: "9:16", value: "9/16", icon: NineBySixteen },
    { id: "2/3", label: "2:3", value: "2/3", icon: TwoByThree },
    { id: "21/9", label: "21:9", value: "21/9", icon: TwentyOneByNine },
    { id: "4/3", label: "4:3", value: "4/3", icon: FourByThree },
    { id: "3/2", label: "3:2", value: "3/2", icon: ThreeByTwo },
    { id: "3/4", label: "3:4", value: "3/4", icon: ThreeByFour },
    { id: "2/1", label: "2:1", value: "2/1", icon: TwoByOne },
];

export const EDITOR_CONTROLS = [
    { label: "ZoomOut", value: "zoomOut", icon: "ic_minus", disable: true },
    { label: "ZoomIn", value: "zoomIn", icon: "ic_add", disable: true },
    { label: "Stroke", value: "stroke", icon: "divider", disable: true },
    { label: "Undo", value: "undo", icon: "ic_undo", disable: true },
    { label: "Redo", value: "redo", icon: "ic_redo", disable: true },
    { label: "Stroke", value: "stroke", icon: "divider", disable: true },
    { label: "Delete", value: "delete", icon: "ic_trash", disable: true },
];

// src/constants/api.constants.js
export const API_TYPE = {
    PANEL: "panel",
    PUBLIC: "public",
};

export const HTTP_STATUS = {
    RATE_LIMITED: 429,
    UNAUTHORIZED: 401,
    NOT_FOUND: 404,
    TIMEOUT: 408,
    SERVER_ERROR: 500,
};

export const STATUS = {
    ACCEPTED: "ACCEPTED",
    PREPARING: "PREPARING",
    RUNNING: "RUNNING",
    SUCCESS: "SUCCESS",
    FAILURE: "FAILURE",
};

export const ERROR_TYPES = {
    RATE_LIMITED: "RATE_LIMITED",
    UNAUTHORIZED: "UNAUTHORIZED",
    NETWORK: "NETWORK",
    SERVER: "SERVER",
    UNKNOWN: "UNKNOWN",
};

export const API_URLS = {
    [API_TYPE.PANEL]: {
        predictions: (orgId) => `/service/panel/transformation/v1.0/org/${orgId}/predictions`,
        status: (orgId, id) => `/service/panel/transformation/v1.0/org/${orgId}/predictions/${id}`,
    },
    [API_TYPE.PUBLIC]: {
        predictions: "/service/public/transformation/v1.0/predictions",
        status: (id) => `/service/public/transformation/v1.0/predictions/${id}`,
    },
};

export const HEADSHOT_URLS = {
    getHeadshotStyles: (orgId) =>
        `${environment.JIRAIYA_PNL_URL}/v1.0/org/${orgId}/plugins/aiheadshot/styles`,
    startInspect: (orgId) =>
        `${environment.JIRAIYA_PNL_URL}/v1.0/org/${orgId}/predictions/aiheadshot/inspect`,
    fetchInspectStatus: (orgId, predictionId) =>
        `${environment.JIRAIYA_PNL_URL}/v1.0/org/${orgId}/predictions/${predictionId}`,
    startHeadshotGeneration: (orgId) =>
        `${environment.HIDAN_PNL_URL}/v1.0/org/${orgId}/predictions/aiheadshot/generate`,
    getUserHeadshots: (orgId, pageNo) =>
        `${environment.HIDAN_PNL_URL}/v1.0/org/${orgId}/predictions/aiheadshot/generate?pageNo=${pageNo}`,
    getHeadshotDetails: (orgId, predictionId) =>
        `${environment.HIDAN_PNL_URL}/v1.0/org/${orgId}/predictions/${predictionId}`,
};

// src/config/transformations.js
export const TRANSFORMATIONS = {
    [TRANSFORMATION_TYPE[0]]: {
        endpoint: "erase/bg",
        defaultParams: {
            industry_type: "general",
            quality_type: "original",
        },
        requiredFiles: ["image"],
        availableFor: [API_TYPE.PANEL, API_TYPE.PUBLIC],
    },
    [TRANSFORMATION_TYPE[1]]: {
        endpoint: "wm/remove",
        defaultParams: {
            rem_text: false,
            rem_logo: false,
        },
        requiredFiles: ["image"],
        availableFor: [API_TYPE.PANEL, API_TYPE.PUBLIC],
    },
    [TRANSFORMATION_TYPE[2]]: {
        endpoint: "sr/upscale",
        defaultParams: {
            type: "2X",
        },
        requiredFiles: ["image"],
        availableFor: [API_TYPE.PANEL, API_TYPE.PUBLIC],
    },
};

export const SUPPORTED_FORMATS = ["png", "jpeg", "jpg", "webp", "heic", "heif"];

export const transformationMap = {
    "/mini-studio/eraseBg": TRANSFORMATION_TYPE[0],
    "/mini-studio/watermarkRemover": TRANSFORMATION_TYPE[1],
    "/mini-studio/upscaler": TRANSFORMATION_TYPE[2],
};

export const NAVBAR_LIST = [
    { title: "Products", value: "products", link: "" },
    { title: "Tools", value: "tools", link: "" },
    { title: "Pricing", value: "pricing", link: "" },
    { title: "Use Case", value: "usecase", link: "" },
    { title: "Resources", value: "resourses", link: "" },
];

export const COLOR_LIST = [
    "conic-gradient(from 180deg at 50% 50%, #EB0D0D 0deg, #FE0 60.00000178813934deg, #39FF5B 120.00000357627869deg, #00FFB7 180deg, #79C4DB 240.00000715255737deg, #F203FF 299.9999928474426deg, #EB0D0D 360deg)",
    "#EC1A24",
    "#FF652F",
    "#FFA937",
    "#FFC34F",
    "#FFF42D",
    "#E5EC33",
    "#A0DB54",
    "#52CB5E",
    "#13994A",
    "#00592A",
    "#41CA87",
    "#2EBCB0",
    "#006BB5",
    "#0F247F",
    "#0C0E5C",
    "#521B7C",
    "#7F107D",
    "#88004B",
    "#CA0053",
    "#EF217A",
    "#DDC7AD",
    "#A59280",
];
