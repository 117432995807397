import React from "react";
export const Freeform = ({ stroke = "#000000A6" }) => {
    return (
        <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.5 5.75V3.125C0.5 1.6725 1.6725 0.5 3.125 0.5H5.75"
                stroke={stroke}
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M21.5 16.25V18.875C21.5 20.3275 20.3275 21.5 18.875 21.5H16.25"
                stroke={stroke}
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M16.25 0.5H18.875C20.3275 0.5 21.5 1.6725 21.5 3.125V5.75"
                stroke={stroke}
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M5.75 21.5H3.125C1.6725 21.5 0.5 20.3275 0.5 18.875V16.25"
                stroke={stroke}
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
        </svg>
    );
};

export const Original = ({ stroke = "#000000A6" }) => {
    return (
        <svg
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.75 6.875V4.25C0.75 2.31625 2.31625 0.75 4.25 0.75H6.875"
                stroke={stroke}
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M25.25 19.125V21.75C25.25 23.6838 23.6838 25.25 21.75 25.25H19.125"
                stroke={stroke}
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M19.125 0.75H21.75C23.6838 0.75 25.25 2.31625 25.25 4.25V6.875"
                stroke={stroke}
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M6.875 25.25H4.25C2.31625 25.25 0.75 23.6838 0.75 21.75V19.125"
                stroke={stroke}
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M18.25 6H7.75C6.7835 6 6 6.7835 6 7.75V18.25C6 19.2165 6.7835 20 7.75 20H18.25C19.2165 20 20 19.2165 20 18.25V7.75C20 6.7835 19.2165 6 18.25 6Z"
                stroke={stroke}
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
        </svg>
    );
};

export const FiveByFour = ({ stroke = "#000000A6" }) => {
    return (
        <svg
            width="36"
            height="30"
            viewBox="0 0 36 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M35.5 25.5V4.5C35.5 2.567 33.933 1 32 1L4 1C2.06701 1 0.5 2.567 0.5 4.5V25.5C0.5 27.433 2.06701 29 4 29L32 29C33.933 29 35.5 27.433 35.5 25.5Z"
                stroke={stroke}
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M0.5 4.5L0.5 25.5C0.5 27.433 2.067 29 4 29L32 29C33.933 29 35.5 27.433 35.5 25.5V4.5C35.5 2.567 33.933 1 32 1L4 1C2.067 1 0.5 2.567 0.5 4.5Z"
                stroke={stroke}
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M28.5 25.5H30.25C31.2125 25.5 32 24.7125 32 23.75V22"
                stroke={stroke}
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M7.5 4.5H5.75C4.7875 4.5 4 5.2875 4 6.25V8"
                stroke={stroke}
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
        </svg>
    );
};

export const OneByOne = ({ stroke = "#000000A6" }) => {
    return (
        <svg
            width="44"
            height="26"
            viewBox="0 0 44 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.01855 4.6875H6.26855C5.30605 4.6875 4.51855 5.475 4.51855 6.4375V8.1875"
                stroke={stroke}
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M43.0098 4.6875V21.3125C43.0098 23.2455 41.4428 24.8125 39.5098 24.8125L4.50977 24.8125C2.57677 24.8125 1.00977 23.2455 1.00977 21.3125V4.6875C1.00977 2.7545 2.57677 1.1875 4.50977 1.1875L39.5098 1.1875C41.4428 1.1875 43.0098 2.7545 43.0098 4.6875Z"
                stroke={stroke}
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M36.0176 21.3125H37.7676C38.7301 21.3125 39.5176 20.525 39.5176 19.5625V17.8125"
                stroke={stroke}
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
        </svg>
    );
};
export const FourByFive = ({ stroke = "#000000A6" }) => {
    return (
        <svg
            width="30"
            height="36"
            viewBox="0 0 30 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.5 35.5H25.5C27.433 35.5 29 33.933 29 32L29 4C29 2.06701 27.433 0.5 25.5 0.5H4.5C2.567 0.5 1 2.06701 1 4L1 32C1 33.933 2.567 35.5 4.5 35.5Z"
                stroke={stroke}
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M25.5 28.5V30.25C25.5 31.2125 24.7125 32 23.75 32H22"
                stroke={stroke}
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M4.5 7.5V5.75C4.5 4.7875 5.2875 4 6.25 4H8"
                stroke={stroke}
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
        </svg>
    );
};

export const NineBySixteen = ({ stroke = "#000000A6" }) => {
    return (
        <svg
            width="26"
            height="44"
            viewBox="0 0 26 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.68848 8V6.25C4.68848 5.2875 5.47598 4.5 6.43848 4.5H8.18848"
                stroke={stroke}
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M4.69727 43H21.3223C23.2553 43 24.8223 41.433 24.8223 39.5L24.8223 4.5C24.8223 2.56701 23.2553 1 21.3223 1H4.69727C2.76427 1 1.19727 2.56701 1.19727 4.5L1.19727 39.5C1.19727 41.433 2.76427 43 4.69727 43Z"
                stroke={stroke}
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M21.313 36V37.75C21.313 38.7125 20.5255 39.5 19.563 39.5H17.813"
                stroke={stroke}
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
        </svg>
    );
};

export const TwoByThree = ({ stroke = "#000000A6" }) => {
    return (
        <svg
            width="22"
            height="34"
            viewBox="0 0 22 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M18 1.25H4C2.067 1.25 0.5 2.817 0.5 4.75V29.25C0.5 31.183 2.067 32.75 4 32.75H18C19.933 32.75 21.5 31.183 21.5 29.25V4.75C21.5 2.817 19.933 1.25 18 1.25Z"
                stroke={stroke}
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M4 8.21484V6.46484C4 5.50234 4.7875 4.71484 5.75 4.71484H7.5"
                stroke={stroke}
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M18 25.75V27.5C18 28.4625 17.2125 29.25 16.25 29.25H14.5"
                stroke={stroke}
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
        </svg>
    );
};

export const TwentyOneByNine = ({ stroke = "#000000A6" }) => {
    return (
        <svg
            width="44"
            height="20"
            viewBox="0 0 44 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.5 7.99609V6.24609C4.5 5.28359 5.2875 4.49609 6.25 4.49609H8"
                stroke={stroke}
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M4.5 19.002L39.5 19.002C41.433 19.002 43 17.4349 43 15.502V4.5032C43 2.5702 41.433 1.0032 39.5 1.0032L4.5 1.0032C2.56701 1.0032 1 2.5702 1 4.5032V15.502C1 17.4349 2.56701 19.002 4.5 19.002Z"
                stroke={stroke}
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M39.5 11.4961V13.2461C39.5 14.2086 38.7125 14.9961 37.75 14.9961H36"
                stroke={stroke}
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
        </svg>
    );
};

export const FourByThree = ({ stroke = "#000000A6" }) => {
    return (
        <svg
            width="30"
            height="22"
            viewBox="0 0 30 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M29 18V4C29 2.067 27.433 0.5 25.5 0.5L4.5 0.5C2.567 0.5 1 2.067 1 4V18C1 19.933 2.567 21.5 4.5 21.5H25.5C27.433 21.5 29 19.933 29 18Z"
                stroke={stroke}
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M8 4H6.25C5.2875 4 4.5 4.7875 4.5 5.75V7.5"
                stroke={stroke}
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M22 18H23.75C24.7125 18 25.5 17.2125 25.5 16.25V14.5"
                stroke={stroke}
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
        </svg>
    );
};

export const ThreeByTwo = ({ stroke = "#000000A6" }) => {
    return (
        <svg
            width="34"
            height="22"
            viewBox="0 0 34 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.25 18L1.25 4C1.25 2.067 2.817 0.5 4.75 0.5L29.25 0.5C31.183 0.5 32.75 2.067 32.75 4V18C32.75 19.933 31.183 21.5 29.25 21.5H4.75C2.817 21.5 1.25 19.933 1.25 18Z"
                stroke={stroke}
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M8.21582 4H6.46582C5.50332 4 4.71582 4.7875 4.71582 5.75V7.5"
                stroke={stroke}
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M25.75 18H27.5C28.4625 18 29.25 17.2125 29.25 16.25V14.5"
                stroke={stroke}
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
        </svg>
    );
};

export const ThreeByFour = ({ stroke = "#000000A6" }) => {
    return (
        <svg
            width="22"
            height="30"
            viewBox="0 0 22 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M18 1H4C2.067 1 0.5 2.567 0.5 4.5V25.5C0.5 27.433 2.067 29 4 29H18C19.933 29 21.5 27.433 21.5 25.5V4.5C21.5 2.567 19.933 1 18 1Z"
                stroke={stroke}
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M4 8V6.25C4 5.2875 4.7875 4.5 5.75 4.5H7.5"
                stroke={stroke}
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M18 22V23.75C18 24.7125 17.2125 25.5 16.25 25.5H14.5"
                stroke={stroke}
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
        </svg>
    );
};

export const TwoByOne = ({ stroke = "#000000A6" }) => {
    return (
        <svg
            width="44"
            height="22"
            viewBox="0 0 44 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M39.5 0.5H4.5C2.567 0.5 1 2.067 1 4V18C1 19.933 2.567 21.5 4.5 21.5H39.5C41.433 21.5 43 19.933 43 18V4C43 2.067 41.433 0.5 39.5 0.5Z"
                stroke={stroke}
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M4.5 7.5V5.75C4.5 4.7875 5.2875 4 6.25 4H8"
                stroke={stroke}
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M39.5 14.5V16.25C39.5 17.2125 38.7125 18 37.75 18H36"
                stroke={stroke}
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
        </svg>
    );
};
